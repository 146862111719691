.pdp-ingredients-module-container {
    padding: 48px 0;
    gap: 40px;

    @include media-breakpoint-up(md) {
        padding: 80px 0;
        margin: 0 auto;
        max-width: 1212px;
    }

    .pdp-ingredients-module-title-wrapper {
        padding: 0 24px;
        width: 100%;
        gap: 16px;

        @include media-breakpoint-up(md) {
            width: 720px;
            padding: 0;
        }

        .pdp-ingredients-module-title {
            color: $color-darkGreen70;
            font-family: $font-qualion;
            font-size: 28px;
            font-weight: 700;
            line-height: 42px;
        }

        .pdp-ingredients-module-subtitle {
            color: $color-darkGreen70;
            font-family: $font-qualion;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
    }

    .pdp-ingredients-module-slide {
        padding: 0px 16px;
        gap: 16px;

        .pdp-ingredients-module-slide-image {
            width: 120px;
            height: 120px;
        }

        .pdp-ingredients-module-slide-description {
            color: $color-darkGreen70;
            font-family: $font-qualion;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }
    }
}
