@import "variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";
@import "productCard";
@import "./custom/variables/variables";
@import "./custom/components/buttons";
@import "./components/cartComponents";
@import "./checkout/freeDeliveryNotice";

.minicart,
.minicart-mobile {
    position: relative;

    * {
        font-family: $font-qualion;
    }

    .product-image {
        width: 64px;
        height: 66px;
    }

    .minicart-header {
        margin-bottom: 35px;

        .number-of-items {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            margin-left: 10px;
            color: $color-darkGreen;
        }

        .basket-icon-minicart {
            width: 20px;
            height: 20px;
            color: $color-darkGreen;
        }

        .close-minicart-button {
            width: 24px;
            height: 24px;
            cursor: pointer;
            color: $color-darkGreen;
        }
    }

    .cart {
        padding: 18px;
        background-color: $color-header-background;
        width: 375px;
        height: 97vh;
        border-radius: 16px;
    }

    .remove-btn {
        float: right;
        background-color: $white;
        border: none;
        padding: 0;
        height: 20px;
        color: $color-neutral40v2;

        &:focus {
            box-shadow: none;
        }
    }

    .product-summary {
        margin-right: -0.938em;
        max-height: calc(100vh - 285px);
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 0.938em;
    }

    .card {
        border: none;
    }

    .card-body {
        padding: 16px 14px;
        background: $white;
        border-radius: 5px;
    }

    .quantity-label {
        font-size: 0.813em;
    }

    .popover {
        position: fixed;
        right: -400px;
        left: auto;
        min-width: 375px;
        max-width: 375px;
        min-height: 97vh;
        display: block;
        transition: all .5s ease-in;
        background: transparent;
        border: none;
        margin: 12px 24px 0 10px;
        border-radius: 16px;

        &::before {
            left: auto;
            right: 15px;
        }

        &::after {
            left: auto;
            right: 16px;
        }

        &.show {
            display: block;
            right: 0;
            position: fixed;
            transition: all 0.5s ease-in 0s;

            @include media-breakpoint-down(xs) {
                display: none;
            }
        }
    }

    .estimated-total {
        margin-top: 0.625em;
    }

    .sub-total-label {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    .sub-total {
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
    }

    .line-item-divider {
        margin: 12px -10px 18px -10px;
        height: 1px;
        background-color: $color-header-background;
        border: none;
    }

    .line-item-name {
        white-space: unset;

        span {
            font-size: 16px;
            color: $black;
            font-weight: 700;
            line-height: 24px;
        }
    }

    .line-item-total-price {
        display: flex;
        justify-content: flex-end;

        .price {
            font-size: 16px;
            font-weight: 700;

            .strike-through {
                font-size: 14px;
                color: $black;
                text-decoration: none;
                margin-right: 0;
            }

            .black-total {
                color: $black;
            }

            .red-total {
                color: $color-error;
                display: flex;
            }
        }
    }

    .bundle-includes {
        font-size: 14px;
        margin-top: 10px 0;
    }

    .bundled-line-item {
        .item-image {
            padding-top: 0;
        }
    }

    .minicart-total {
        width: 93px;
        height: 43px;
        border-radius: 4px;
        margin-left: 15px;
        background-color: transparent;
        cursor: pointer;
        padding: 14px 26px;

        align-items: center;
        display: flex;
        justify-content: center;

        @include media-breakpoint-up(lg) {
            border: 2px solid $color-darkGreen;
        }

        @include media-breakpoint-down(md) {
            width: 57px;
            height: 32px;
        }

        &:hover {
            opacity: 0.5;
        }
    }

    .cart-continue  {
        a {
            text-decoration: none;
            font-size: 18px;
            font-weight: 700;
            line-height: 18px;
        }
    }

    .minicart-total.active {
        background-color: $color-gold;
        border: 2px solid $color-gold;

        .minicart-quantity {
            color: $color-darkGreen;
        }
    }

    .minicart-quantity {
        font-size: 18px;
        font-weight: 700;
        color: $color-darkGreen;
    }

    .price-discount {
        display: none;
    }

    .minicart-quantity.active {
        color: $white;
    }

    .modal.fade.show {
        background-color: rgba(0, 0, 0, 0.5);
        transition: background-color 0.3 ease-in-out;
    }
}

.bonus-product-title {
    padding: 0 22px 0 14px;
    background-color: $white;
    margin-top: -18px;
}

.card {
    &.bonus-item {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding: 0 22px 24px 14px;
    }
}

.product-summary {
    .bonus-product-title {
        margin-top: -30px;
    }

    .bonus-item {
        padding: 0 0 14px 0;

        .card-body {
            padding: 0 14px;
        }
    }
}

.minicart-background.active {
    width: 100%;
    background: $black;
    height: 100vh;
    position: fixed;
    z-index: 1040;
    top: 0;
    left: 0;
    opacity: 0.5;
}

.modal-dialog {
    @include media-breakpoint-down(sm) {
        position: fixed;
        bottom: 0;
        margin: 0;
    }
}

#removeProductModal {
    .delete-confirmation-header {
        border-bottom: none;

        .close {
            margin-right: 40px;

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }

    .modal-content {
        border-radius: 15px;
    }

    .delete-confirmation-body {
        padding: 0 40px 40px 40px;
        text-align: center;
    }

    .product-to-remove {
        border: 1px solid $color-neutral10;
        padding: 10px 10px 10px 20px;
        border-radius: 4px;
        font-weight: 700;
        margin: 20px 0;
        display: flex;
        justify-content: left;
    }

    .msg-delete-product {
        width: 90%;
        font-size: 16px;
        line-height: 24px;
    }

    .msg-delete-account {
        color: $black;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        padding-bottom: 28px;
    }

    .radio-text {
        color: $color-neutral80;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    .modal-delete-product-title,
    .modal-delete-account-title {
        font-size: 24px;
        font-weight: 700;
    }

    .modal-dialog {
        top: 50%;
        left: 50%;
        margin: 0;
        transform: translate(-50%, -50%);

        @include media-breakpoint-down(sm) {
            position: fixed;
            top: auto;
            width: 100%;
            bottom: 0;
            transform: translate(-50%, 0);
        }
    }

    .modal-footer {
        border-top: none;
        padding: 0;
    }

    .remove-product-buttons,
    .modal-btn-group {
        display: flex;
        width: 100%;
        gap: 15px;

        @include media-breakpoint-down(sm) {
            flex-direction: column-reverse;
        }
    }
}
