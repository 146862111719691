.product-detail {
    .quantity {
        background-color: transparent;
        text-align: center;
        border-left: 0 !important;
        border-right: 0 !important;
        border-radius: 0 !important;
        font-weight: 700 !important;
        font-size: 20px !important;
        line-height: 20px !important;
        font-family: $font-qualion;
        height: 56px !important;
        outline-width: 0;

        &:focus,
        &:active {
            border-color: $color-neutral20  !important;
        }
    }

    input[type="number"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }

    .number-input {
        display: inline-flex;
        width: 100%;

        &.disabled {
            color: $color-neutral20;
            pointer-events: none;
        }
    }

    .number-input,
    .number-input * {
        box-sizing: border-box;
    }

    .number-input button {
        border: 1px solid $color-neutral20;
        border-radius: 0;
        outline: none;
        -webkit-appearance: none;
        background-color: transparent;
        align-items: center;
        justify-content: center;
        height: 56px;
        cursor: pointer;
        margin: 0;
        position: relative;
        width: 20%;

        span {
            width: 100%;
            display: grid;
            place-content: center;
        }

        svg {
            margin-left: 0;
            color: $color-darkGreen;
        }

        &.plus {
            border-left: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        &.less {
            border-right: 0;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &.disabled {
            svg {
                color: $color-neutral20 !important;
            }
        }
    }

    .number-input-input-wrapper {
        width: 80%;
        
        .quantity {
            color: $color-darkGreen;

            &.disabled {
                color: $color-neutral20;
            }
        }
    }
}
