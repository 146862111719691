.pdp-how-to-serve {
    background-color: $color-darkGreen;
    height: auto;
    margin-bottom: 30px;
    @include media-breakpoint-up(lg) {
        margin-bottom: 150px;
        height: 390px;    
    }
    .pdp-hts-image-content {
        display: none;
        height: 100%;
        img {
            width: 95%;
        }
        @include media-breakpoint-up(lg) {
            text-align: center;
            display: block;
            img {
                width: 100%;
                height: auto;
                max-width: 512px;
                padding-top: 40px;
            }
        }
        &.mobile {
            display: block;
            height: 100%;
            img {
                display: block;
                max-width: 344px;
                margin: 0 auto;
                text-align: center;
            }
            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }
    .pdp-hts-image {
        text-align: center;
        display: none;
        @include media-breakpoint-up(lg) {
            display: block;
            img {
                position: relative;
                top: 25%;
                transform: translateY(-40%);
                width: 100%;
                height: auto;
                max-width: 729px;
                max-height: 729px;
            }
        }
    }
}
