.dropdown-asset {
    color: $color-black;
    font-size: 16px;
    line-height: 24px;
    border-top: 1px solid $color-neutral20;
    padding: 24px 0 24px 0;
    .dropdown-asset-icon {
        height: 24px;
        svg{
            width: 24px;
            height: 24px;
            fill: $color-darkGreen70;
            path {
                fill: $color-darkGreen70;
            }
        }
    }
    .dropdown-asset-row {
        cursor: pointer;
    }
    .dropdown-asset-open {
        height: 8px;
        text-align: right;
        svg{
            width: 12px;
            height: 100%;
            fill: $color-darkGreen70;
            cursor: pointer;
            &.flip{
                transform : rotate(180deg);
            }
        }
    }
    .dropdown-asset-title {
        font-size: 16px;
        line-height: 24px;
        color: $color-darkGreen70;
        font-weight: 700;
    }

    .dropdown-asset-content {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $color-black;
        display: grid;
        table {
            border-collapse: separate;
            border-spacing: 0 15px;
            td.text-right {
                font-weight: 700;
            }
        }
    }
    a[class^="link-"]  {
        text-underline-offset: 4px;
        font-size: 14px;
        line-height: 20px;
    }
}
