.product-type {
    color: $color-darkGreen;
    font-family: $font-qualion;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;

    @include media-breakpoint-down(sm) {
        font-size: 18px;
        line-height: 26px;
    }
}

.pdp-product-type {
    max-width: fit-content;
}
