h1.name {
    font-family: $font-qualion;
    font-size: 32px;
    font-weight: 700;
    line-height: 50px;
    color: $color-darkGreen;

    @include media-breakpoint-down(sm) {
        font-size: 20px;
        line-height: 30px;
    }
}
