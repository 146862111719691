@import "../utilities/swatch";
@import "../variables";
@import "../custom/variables/variables";

.site-search {
    position: relative;
    height: 2.5em; /* 40/16 */

    .header-search-button {
        position: absolute;
        border: none;
        top: 0;
        left: 0;
        padding: 0;
        background-color: transparent;

        &:hover {
            opacity: 0.5;
        }

        &:focus,
        &:active {
            outline: none;
        }

        svg {
            color: $color-neutral50;
        }
    }

    .search-field {
        border: none;
    }

    .search-field::placeholder {
        color: $color-neutral40v2;
    }

    .close-search-button {
        position: absolute;
        border: none;
        top: 5px;
        right: 10px;
        padding: 0;
        background-color: transparent;
        cursor: pointer;
        color: $color-darkGreen;

        &:hover {
            opacity: 0.5;
        }
    }

    input {
        margin-left: 20px;
        height: 44px;
        border: none;
        background-color: $color-header-background;

        &:focus {
            border: none;
            background-color: $color-header-background;
            outline: none;
            box-shadow: none;
        }
    }
}

@include media-breakpoint-down(xs) {
    .header-search {
        .site-search {
            display: none;
        }
    }
}

.suggestions-wrapper {
    position: relative;
}

.suggestions {
    display: block;
    position: absolute;
    background-color: $white;
    top: 17px;
    right: 0;
    width: 100%;
    z-index: 3;

    @include media-breakpoint-down(md) {
        display: flex;
        position: fixed;
        width: 100%;
        top: 90px;
        display: flex;
        position: fixed;
        width: 100%;
    }

    .swatch-circle {
        @include swatch(2.5em, $white);

        margin-right: 0.85em;
    }

    .header {
        color: $grey4;
        font-size: 0.875em;
        padding-top: 0.625em;

        &:not(:first-child) {
            border-top: 1px solid $grey3;
        }
    }

    .items {
        padding: 0.313em 0;

        @include media-breakpoint-down(md) {
            margin: 0;
        }

        .list {
            padding: 0;
            list-style-type: none;
        }

        .link {
            text-decoration: none;

            &:hover .name {
                text-decoration: underline;
            }
        }
    }

    .item {
        padding-bottom: 0.625em;

        .name {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            padding-bottom: 15px;
            border-bottom: 1px solid $color-neutral20;

            @include media-breakpoint-down(md) {
                margin: 0 -15px;
                padding: 15px 15px;
            }

            a {
                color: $black;
            }
        }

        .ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        @include media-breakpoint-down(xs) {
            padding-bottom: 0.938em;
        }
    }

    .category-parent {
        color: $grey4;
        font-size: 0.875em;
    }

    .selected {
        background-color: $grey2;
    }

    .container {
        list-style-type: none;
    }
    .content-guide{
        cursor: pointer;
        margin-bottom: 32px;
        .type{
            color: $color-darkGreen;
            font-size: 12px;
            line-height: 18px;
            font-weight: 700;
            text-transform: uppercase;
        }
        .name{
            color: $color-darkGreen;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            margin-top: 0;
        }
        .excerpt{
            color: $color-darkGreen;
            font-size: 14px;
            line-height: 20px;
            margin-top: 0.5rem;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }

    .search-suggestions-products {
        .product-tile {
            .info-content {
                position: inherit;
            }

            .tile-feefo {
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }
    }
}

.more-below {
    -moz-border-radius: 1.25em;
    background: $grey3;
    border: 0.063em solid rgba(0, 0, 0, 0.1);
    border-radius: 1.25em;
    bottom: 1.875em;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
    display: none;
    height: 2.5em;
    position: fixed;
    right: 1.875em;
    width: 2.5em;

    i.fa-long-arrow-down {
        border-radius: 50%;
        color: $white;
        display: table-caption;
        height: 0.75em;
        font-size: 1.5rem;
        left: 0.57em;
        line-height: 0.8em;
        position: absolute;
        top: 0.4em;
        width: 0.8em;
    }
}
