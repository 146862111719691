.veil {
	position: absolute;
	z-index: 100;
	text-align: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.underlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.5;
		background-color: $black;
	}
}

$spinner-size: 80px;

.spinner {
	position: absolute;
	top: 25%;
	left: 50%;
	transform-origin: 50% 50%;
	transform: translate(-50%, 0%);
	font-size: 50px;
	width: 1em;
	height: 3em;
	color: $color-white;

	.paw {
		width: 1em;
		height: 1em;
		animation: 2050ms pawAnimation ease-in-out infinite;
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAdCAYAAAC9pNwMAAACcklEQVRIia2XT0hUURTGvxnK/ggSVJAEjghaMNpKCKIcw1V/aCHZJoKCoLBFy4i2BgltqmWbICnKKKgWtYkwWlVQZGHWIoM0oj9TURFmvxbvPbg+7z2vnnPgcrnn++73zbvvnDszAmSMFuA68BQ4CzQZ3IPAGPARuAAssbQt0+XAF2bHN6Ds4Q4yNx7kNT7lEQOYAgoOb0OAB7ArpF9UOHoC+VWS9jvrAUNjawiwjBsMbE8810vabPBW5DH+YWDleG42OJI0k8d4zMCWxfPSDONXeYyHDexnPL/NML4aRKySByYD1XrP4UwFOA9TWl1AT7qdtgBDwDmg2yF3AH88ohWH0+vBvwKrY3wT8MTBRoBFAvo8G084wuuAO/EHeA8cYO7JHALeAdPATaA5zq8PnMYZAeMB8GhKvAmo95gmYzHQ6KyLwIeA9mcBvwIgQMkwyhr7DN3pooxek3Q4o2qt6DWwT0VJLw1CJUPcasc2A3tclHTRIPiuvN2SLkt6IWlC0iNJJyW1pHgLDd3TSVGkv/6SeOa8sy5g1Hhvv4F+hz8U4N3G6eNO/P16JMZ9LReKwXhPCZhJYXeBBa6xgHbglkO6FOfX/IdpEkkrthM9+TBRlZs/BNYS3VjJeiKHMTjXo29k9eKxnKYA34nqx6tdAEKV1yCpKqlgVGdWXJHU5wOsPuyfp6kk7ZS00QdYTzwuqXWexlLU553/atwaG9cqOiSNuonQUXfX0FSSdqQTIeNqjY1XphOho65TdNQlDzYp6Yak+5Jex7mypG2StgeMK5JGZmWMHm4ErgFviP4TnSe6OuuMPW3AceA5UCW66/f6uH8BH0ffcZYEFMMAAAAASUVORK5CYII=");
		background-repeat: no-repeat;
		opacity: 0;

		&:nth-child(odd) {
			transform: rotate(-10deg);
		}

		&:nth-child(even) {
			transform: rotate(10deg) translate(125%, 0);
		}

		@for $i from 1 through 6 {
			&:nth-child(#{$i}) {
				animation-delay: #{ (($i * -1)+6)*0.25 }s;
			}
		}

		.no-cssanimations & {
			opacity: 1;
		}
	}
}

@keyframes pawAnimation {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}
}