@import "variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.item-attributes {
    vertical-align: top;
    padding-left: 0;
}

.line-item-attributes,
.line-item-option {
    font-size: 0.813rem;
    margin: 0;
}

.line-item-name {
    text-overflow: ellipsis;
    overflow: hidden;
    color: $darker-gray;
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 0.313em;

    a {
        color: $black;
    }
}

.product-weight {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: $color-neutral60;
}

.line-item-pricing-info {
    margin-bottom: 0;

    + .price {
        font-size: 1em;
        font-weight: bolder;
    }

    + .unit-price .price {
        font-size: 1em;
        font-weight: bolder;
    }
}

.line-item-price-quantity-info {
    margin-top: 0.625em;
    border-top: 1px solid $horizontal-rule-grey;
}

.line-item-total-text {
    font-size: 0.813em;
}

.pricing {
    font-size: 1em;
    font-weight: bolder;
}

.item-image {
    max-width: 64px;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;

    .frozen-icon {
        width: 24px;
        position: absolute;
        height: 24px;
        bottom: -10px;
        display: flex;
    }

    img.product-image {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        max-height: 100%;
        height: auto;
        width: auto;
    }
}

.product-summary-block .item-image {
    img.product-image,
    svg.mp-product-image {
        max-height: 66px;
    }
}

.non-adjusted-price {
    display: none;
}

.line-item-promo {
    color: $success;
    font-size: 0.813em;
}

.line-item-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.bundled-line-item + .bundled-line-item {
    margin-top: 0.625em;
}

.bundle-includes {
    font-size: 0.813em;
    margin-bottom: 0.625em;
}

.line-dotted {
    border-top: 0.063em dashed #ccc;
}

.line-item-availability {
    font-size: 0.813rem;
}

.product-line-item-details {
    overflow-y: auto;
}

.cart-page {
    padding-bottom: 86px;

    .remove-btn {
        &:focus,
        &:active {
            outline: none;
            box-shadow: none;
        }
    }

    .line-item-divider {
        margin: 18px -22px 18px -14px;
        border-top: 1px solid $color-neutral10;
    }

    .weeks-block {
        border: 1px solid $color-neutral20;

        .btn-week {
            &:active {
                &::after {
                    height: 4px;
                    border-radius: 20%;
                }
            }
        }
    }
}

.bonus-line-item-msg,
.default-bonus-line-item-msg {
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    padding-bottom: 20px;
    font-feature-settings: "tnum";
    text-transform: uppercase;
    color: $color-neutral40v2;
}

.bonus-product-line-item,
.product-line-item {
    .change-product-button {
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        color: $color-darkGreen;
        text-decoration: underline;
        text-transform: inherit;
        cursor: pointer;

        &:hover {
            color: $color-lightGreen;
        }
    }
}

.order-product-summary {
    .card {
        background-color: transparent;
    }
}
