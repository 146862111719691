@keyframes underline-enter {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

.pdp-generic-module-container {
    padding: 48px 24px;
    gap: 40px;

    @include media-breakpoint-up(md) {
        padding: 80px 0;
        margin: 0 auto;
        max-width: 1400px;
        gap: 80px;
    }

    .pdp-generic-module-title {
        color: $color-darkGreen70;
        text-align: center;
        font-family: $font-qualion;
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        width: fit-content;
        z-index: 1;

        &.underline-animation {
            .underline {
                &::after {
                    animation: underline-enter 2s 1;
                }
            }
        }

        .underline {
            z-index: 1;

            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 14px;
                transform: rotate(-2deg);
                left: 0;
                bottom: 0;
                background: $color-gold;
                z-index: -1;
            }
        }

        @include media-breakpoint-up(md) {
            text-align: left;
            font-size: 40px;
            line-height: 48px;
        }
    }

    .pdp-generic-module-image {
        width: 100%;
        border-radius: 24px;

        @include media-breakpoint-up(md) {
            width: 540px;
        }
    }

    .pdp-generic-module-description-wrapper {
        width: 100%;
        gap: 40px;

        @include media-breakpoint-up(md) {
            width: 540px;
            gap: 32px;
        }

        .pdp-generic-module-description {
            color: $color-darkGreen70;
            text-align: center;
            font-family: $font-qualion;
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;

            a {
                color: $color-darkGreen70;
            }

            li {
                text-align: left;
            }

            @include media-breakpoint-up(md) {
                text-align: left;
                font-size: 18px;
                line-height: 26px;
            }
        }

        .pdp-generic-module-button {
            width: fit-content;
            font-size: 18px;
            font-weight: 700;
            line-height: 18px;
            padding: 16px 24px;
        }
    }
}
