@import "../utilities/swatch";
@import "productCommon";
@import "quickView";
@import "../custom/components/pdp/price";
@import "../custom/components/pdp/priceDiscount";
@import "../custom/components/feefo";

.product-tile {
    background: none;
    text-align: center;
    padding: 24px 8px 8px 8px;
    border: none !important;
    margin-bottom: 0;
    @include media-breakpoint-up(md) {
        min-height: 475px;
        height: 516px;
    }
    &.tile-can-hover{
        &:hover{
            background: $color-white;
            border-radius: $border-radius-base;
        }
    }
    .tile-body {
        padding: 0;

        .info-content {
            position: absolute;
            justify-content: space-between;

            @include media-breakpoint-up(md) {
                bottom: 50px;
                width: 95%;
            }

            @include media-breakpoint-down(sm) {
                bottom: 45px;
            }
        }

        .color-swatches {
            min-height: 0;

            .product-tile-color-label {
                cursor: pointer;
            }
        }
        .food-type{
            font-size: 16px;
            line-height: 24px;
            color: $color-darkGreen;
            font-weight: 700;
            text-transform: uppercase;
        }
        .price {
            margin-bottom: 0;
            font-size: 1em;

            .sales{
                .value{
                    font-size: 16px;
                    line-height: 24px;
                }
                &:not(.black-total) {
                    .value {
                        color: $color-error;
                    }
                }
            }
            .strike-through{
                font-size: 14px;
                line-height: 20px;
                color: $color-black;
                font-weight: 700;
            }
            .tiered {
                font-size: 0.875em;

                .value {
                    font-weight: bold;
                }
            }

            .price-discount {
                margin-left: 10px;
            }
            /* override custom price styles */
            @include media-breakpoint-down(xl) {
                .strike-through {
                    font-size: 16px;
                    line-height: 24px;
                    margin-right: 4px;
                }
                .sales {
                    .value {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }
            }
            @include media-breakpoint-down(md) {
                .strike-through {
                    font-size: 12px;
                    margin-right: 4px;
                }
                .sales {
                    .value {
                        font-size: 14px;
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                .strike-through {
                    font-size: 14px;
                    margin-right: 8px;
                }
                .sales {
                    .value {
                        font-size: 16px;
                    }
                }
            }

            .tile-price-wrapper{
                place-content: center;
            }

            .small-weight {
                font-weight: 400;
            }
        }

        .coming-soon-tile {
            text-align: center;
        }

        .pdp-link {
            line-height: 26px;
            font-weight: 700;
            font-size: 18px;

            a {
                text-decoration: none;
            }
        }
        .available-bundles{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $color-neutral80;
            margin-bottom: 16px;
        }
        .tile-feefo {
            .ratings {
                font-size: 0.9em;
                height: 30px;

                &:has(.feefo-rating-stars) {
                    box-shadow: 0px -4px 12px rgba(68, 68, 68, 0.3);
                }

                @include media-breakpoint-down(sm) {
                    box-shadow: none;
                }
            }
        }
        .tile-body-footer{
            text-align: center;
            place-content: center;
        }
        .alert{
            margin: 0 auto;
            text-align: center;
            place-content: center;

            @include media-breakpoint-down(md) {
                .alert-availability-svg {
                    height: 22px;
                }
            }
        }
    }
    .bundle-tile {
        @include media-breakpoint-up(md) {
            padding-top: 50px;
        }
    }
    .info-content {
        &.type {
            @include media-breakpoint-up(lg) {
                display: flex;
                justify-content: center;
            }
            @include media-breakpoint-down(md) {
                margin-left: 0px;
                margin-right: 0px;
            }
            @include media-breakpoint-down(sm) {
                flex-direction: column-reverse;
            }
        }

        .product-type {
            line-height: 25px;
            font-weight: 700;
            font-size: 16px;
            color: $color-darkGreen;
        }
    }

    .image-container {
        margin-bottom: 32px;
        position: relative;
        overflow: auto;
        width: 100%;
        height: 244px;

        @include media-breakpoint-down(md) {
            height: 146px;
        }

        .quickview {
            display: none;
            position: absolute;
            bottom: 1rem;
            right: 1rem;

            i {
                &.fa-expand {
                    color: rgba(0, 0, 0, 0.7);
                }

                &.fa-circle {
                    color: rgba(255, 255, 255, 0.7);
                    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
                }
            }
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            .tile-image {
                height: auto;
                width: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }
        .new-product-badge {
            width: 48px;
            height: 48px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }
    }

    .swatches {
        a {
            text-decoration: none;
        }
    }

    .swatch-circle {
        // $white will be replaced by color image background via Javascript
        @include swatch(1.8em, $white);
    }
    .tile-add-to-cart{
        position: absolute;
        bottom: 8px;
        width: 90%;
        left: 5%;
        place-content: center;
        margin: 0 auto;
        display: flex;

        @include media-breakpoint-down(md) {
            position: relative;
            left: 0;
            bottom: 0;
        }
    }
    @include media-breakpoint-down(sm) {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        border-bottom: 1px solid $color-neutral20 !important;
        &.tile-can-hover{
            &:hover{
                background: none;
                border-radius: 0;
            }
        }
        .image-container{
            flex: 0 0 33%;
            margin-bottom: 0 !important;
            padding: 16px;
            .new-product-badge{
                width: 36px;
                height: 36px;
                top: 8px;
            }
        }
        .tile-body{
            flex: 0 0 66%;
            text-align: left;
            padding: 8px;
            .available-bundles{
                font-size: 12px;
                line-height: 18px;
            }
            @include media-breakpoint-down(md) {
                .food-type{
                    font-size: 13px;
                    line-height: 20px;
                }
                .pdp-link{
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
        .tile-add-to-cart{
            svg{
                margin-left: 0;
            }
        }
        .tile-small-feefo-add-to-cart{
            flex: 0 0 28%;

            .tile-add-to-cart-sm, .tile-feefo-sm{
                height: 50%;
                padding: 8px;
            }
            .tile-add-to-cart-sm{
                place-content: end center;
                .tile-add-to-cart{
                    margin: 0 auto;
                    width: 100%;
                    .col-sm-12{
                        padding: 0px;
                        margin: 0 auto;
                    }
                    svg{
                        margin-left: 0;
                    }
                }
            }
        }
    }

}
.recommendations .product-tile{
    @include media-breakpoint-down(sm) {
        border-bottom: 0 !important;
        .image-container{
            flex: 0 0 100%;
            margin-bottom: 32px !important;
            padding: unset;
            .new-product-badge{
                width: 48px;
                height: 48px;
                top: 0;
                left: 0;
            }
        }
        .tile-body{
            flex: 0 0 100%;
            text-align: unset;
            align-self: unset;
            padding: unset;
            &.not-available{
                flex: 0 0 100%;
            }
            .available-bundles{
                font-size: 14px;
                line-height: 20px;
            }
            .food-type{
                font-size: 16px;
                line-height: 24px;
            }
            .pdp-link{
                font-size: 18px;
                line-height: 26px;
            }
        }
        .tile-small-feefo-add-to-cart{
            display: none;
        }
        .tile-price-wrapper{
            display: block !important;

        }
        .tile-price-wrapper-sm{
            display: none !important;
        }
        .price{
            .sales{
                .value{
                    font-size: 20px;
                    line-height: 30px;
                }
            }
            .strike-through{
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}

.choose-bonus-product-dialog {
    @include media-breakpoint-down(md) {
        position: initial !important;
    }
}

#chooseBonusProductModal {
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;

    .modal-header {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        color: $color-neutral80;
        border-bottom: none;
        padding: 48px 48px 0 48px;

        @include media-breakpoint-down(md) {
            padding: 32px 15px 15px 15px;
        }
    }

    .modal-footer {
        padding: 0 48px 48px 48px;
        border-top: none;

        @include media-breakpoint-down(md) {
            padding: 0 15px 48px 15px;
        }

        .container {
            margin-left: 0;
            width: 100%;
            margin-right: 0;
        }
    }

    .select-cbp-container {
        margin-top: auto;
        margin-bottom: auto;
    }

    .product-name-wrapper {
        width: 100%;
    }

    .bonus-quantity,
    .bonus-option {
        margin-top: 0.938em;
    }

    .selected-items-msg {
        padding: 16px 18px;
    }

    .bonus-product-item {
        @include media-breakpoint-down(md) {
            padding: 15;
        }
    }

    .modal-body,
    .product-standard {
        @include media-breakpoint-down(md) {
            padding: 0;
        }
    }

    .bonus-quantity-select {
        min-width: 5em;
    }

    .bonus-summary {
        font-size: 16px;
        font-weight: 700;
    }

    .selected-bonus-products {
        border: 1px solid $color-neutral20;
        border-radius: 4px;
    }

    .selected-pid {
        padding: 16px;

        .item-image {
            height: auto;
            width: auto;
        }

        .bonus-product-name {
            .name {
                font-weight: 700;
                color: $black;
                font-size: 16px;
                line-height: 24px;
            }
        }

        .product-weight {
            font-weight: 700;
            font-size: 12px;
            color: $color-neutral60;
            line-height: 18px;
        }

        .remove-icon {
            cursor: pointer;
        }
    }

    .bonus-product-price {
        text-align: center;
        margin-top: 1em;
    }

    .carousel-image {
        width: 60%;
    }

    .pdp-name {
        .name {
            font-size: 18px;
            color: $color-darkGreen;
            line-height: 24px;
        }
    }

    button.close {
        font-size: 24px;
    }

    .select-bonus-product,
    .add-bonus-products {
        height: 56px;
    }
}
.carousel-inner.row .product-tile {
    .tile-small-feefo-add-to-cart {
        display: none;
    }
}