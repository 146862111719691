.subscription {
    border-radius: 4px;
    color: $color-black;
    background-color: $color-white;
    min-height: 86px;
    height: auto;

    .subscription-text {
        .subscription-text-claim {
            margin-left: 18px;
            margin-top: 18px;
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;

            .subscription-text-claim-highlighted {
                color: $color-gold;
            }
        }

        .subscription-text-repeat {
            margin-left: 18px;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
        }
    }

    .subscription-switch {
        display: flex;
        height: 86px;
        place-content: end right;

        .form-check {
            display: grid;
            place-content: end;

            .form-check-toggle {
                margin-bottom: 18px;
            }
        }
    }

    .subscription-time {
        height: 67px;
        margin: 0 auto;
        margin-top: 18px;
        margin-bottom: 18px;
        width: 93%;

        .tab-title {
            margin-bottom: 8px;
        }
    }
}

.pdp-subscription {
    #SorDeliveryWeekInterval {
        display: flex;
        margin-left: 0;
        border-radius: 4px;
    }

    .smart-order-refill-options {
        .full_row {
            padding: 0;
        }
    }

    .subscription-title {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 700;
        padding: 16px;
        background-color: $color-gold;
        align-items: center;

        .text-wrapper {
            padding-bottom: 6px;

            .subscribe-text {
                color: $color-darkGreen;
            }

            .save-text {
                color: $color-darkGreen;
            }
        }

        .switch-msg {
            text-transform: initial;
            color: $color-darkGreen;
        }
    }

    input[type="checkbox"].input-switch {
        -webkit-appearance: none;
        appearance: none;
        position: relative;
        width: 44px;
        height: 25px;
        border-radius: 34px;
        border: 1px solid $color-white;
        background-color: $color-neutral20;
        cursor: pointer;
        -webkit-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;

        &:before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            margin: 1.5px;
            background-color: $white;
            border-radius: 100%;
            transition: all 0.3s ease-in-out;
        }

        &:checked {
            background-color: $color-darkGreen;

            &:before {
                transform: translateX(+100%);
            }
        }
    }

    .repeat-order-block {
        font-size: 14px;
        line-height: 20px;
        border-radius: 4px;
        &.noInferiorBorder {
            border-radius: 4px 4px 0 0;
        }

        .order-sale {
            color: $color-gold;
            font-size: 12px;
            font-weight: 700;
            margin-right: 16px;
        }

        .text-wrapper {
            .save-text {
                font-weight: 900;
                font-size: 18px !important;
                line-height: 26px !important;
            }
            .subscribe-text {
                font-weight: 400;
                font-size: 18px !important;
                line-height: 26px !important;
            }
        }
    }
    .weeks-block {
        padding: 16px;
        background-color: $color-gold;
        border-radius: 0 0 4px 4px;

        .btn-week {
            &:active {
                &::after {
                    height: 4px;
                }
            }
        }
    }

    .btn-week {
        width: 100%;
        height: 78px;
        background-color: $color-subscription-interval;
        border: none;
        border-radius: 0;
        color: $color-neutral40v2;
        font-weight: 400;
        text-transform: none;
        line-height: 24px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding-inline-start: 6px;
        padding-inline-end: 6px;

        &:focus {
            outline: none;
        }

        &.active {
            outline: none;
            background: $white;
            color: $color-darkGreen;
            font-weight: 700;

            &::after {
                content: "";
                background-color: $color-darkGreen;
                width: 43px;
                height: 4px;
                position: absolute;
                bottom: 0px;
                border-radius: 40px;
                z-index: 4;
            }
        }

        .number {
            font-size: 20px;
        }

        .week {
            font-size: 14px;
        }
    }
    .btn-week:first-child {
        border-radius: 4px 0 0 4px;
    }
    .btn-week:last-child {
        border-radius: 0 4px 4px 0;
    }

    .pdp-subscription-selection-wrapper {
        gap: 24px;

        @include media-breakpoint-down(sm) {
            gap: 16px;
        }

        .pdp-subscription-selection-one-time-wrapper {
            border-radius: 4px;
            border: 1px solid $color-neutral20;

            &.selected {
                border: 1px solid $color-darkGreen70;

                .pdp-subscription-selection-one-time-radio {
                    &::after {
                        content: '';
                        width: 12px;
                        height: 12px;
                        background-color: $color-darkGreen70;
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }

            .pdp-subscription-selection-one-time-radio {
                width: 28px;
                height: 24px;
                border-radius: 50%;
                border: 2px solid $color-darkGreen70;

                @include media-breakpoint-down(sm) {
                    width: 32px;
                }
            }

            .pdp-subscription-selection-one-time-title {
                color: $color-darkGreen70;
                font-family: $font-qualion;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
            }

            .pdp-subscription-selection-one-time-price {
                color: $color-neutral80;
                font-family: $font-qualion;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
            }
        }

        .pdp-subscription-selection-gap {
            gap: 8px;
        }

        .pdp-subscription-selection {
            cursor: pointer;
        }

        .pdp-subscription-selection-save-wrapper {
            border-radius: 4px;
            border: 1px solid $color-neutral20;

            &.selected {
                border: 1px solid $color-darkGreen70;

                .pdp-subscription-selection-save-radio {
                    &::after {
                        content: '';
                        width: 12px;
                        height: 12px;
                        background-color: $color-darkGreen70;
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                .pdp-subscription-selection-save-interval-wrapper {
                    display: flex;
                }
            }

            .pdp-subscription-selection-save-header-wrapper {
                padding: 12px 16px;
                background-color: #FBDB65;

                .pdp-subscription-selection-save-header {
                    color: $color-darkGreen70;
                    font-family: $font-qualion;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 20px;
                }
            }

            .pdp-subscription-selection-save-option-wrapper {
                border-bottom: 1px solid $color-neutral20;
            }

            .pdp-subscription-selection-save-interval-wrapper {
                display: none;
                border-top: 1px solid $color-neutral20;
            }

            .pdp-subscription-selection-save-radio {
                width: 32px;
                height: 24px;
                border-radius: 50%;
                border: 2px solid $color-darkGreen70;

                @include media-breakpoint-down(sm) {
                    width: 39px;
                }
            }

            .pdp-subscription-selection-save-title {
                color: $color-darkGreen70;
                font-family: $font-qualion;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
            }

            .pdp-subscription-selection-save-interval-title {
                color: $color-neutral50;
                font-family: $font-qualion;
                font-size: 14px;
                font-weight: 700;
                line-height: 22px;
            }

            .pdp-subscription-selection-save-interval-select {
                color: $color-neutral80;
                font-family: $font-qualion;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                height: 44px;
                padding: 12px;
                border-radius: 4px;
                border: 1px solid $color-neutral20;
                width: 100%;
                appearance: none;
                background-color: transparent;
            }

            .pdp-subscription-selection-save-interval-icon {
                right: 12px;
                top: 16px;
                height: 12px;
                width: 12px;
            }

            .pdp-subscription-selection-save-flexibility {
                color: $color-darkGreen70;
                font-family: $font-qualion;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
            }

            .pdp-subscription-selection-save-price {
                gap: 8px;

                .list {
                    color: $color-neutral80;
                    font-family: $font-qualion;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 20px;
                    text-decoration-line: line-through;
                }

                .sales {
                    color: $color-error;
                    font-family: $font-qualion;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 24px;
                }
            }
        }
    }
}
