.main-image {
    max-height: 388px;
    max-width: 100%;
}

figure.zoom-image {
    background-position: 50% 50%;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: zoom-in;
    display: flex;
    align-items: center;
    justify-content: center;
}

figure.zoom-image:hover img {
    opacity: 0;
}

figure.zoom-image img {
    transition: opacity .5s;
    display: block;
}
