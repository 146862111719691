.product-detail {
    .frozen-info,
    .delivery-and-return {
        .frozenInfo-img {
            max-width: 100%;
            height: auto;
        }
        .frozenInfo-text {
            padding-left: 30px;
        }
        .link-green {
            text-transform: uppercase;
        }
    }
}
