@import "../custom/variables/variables";
@import "../utilities/swatch";
@import "productCommon";
@import "../custom/components/pdp/pdp";

.quick-view-dialog,
.choose-bonus-product-dialog {
    max-width: 56.25em;
    width: 100%;

    * {
        font-family: $font-qualion;
    }

    .header {
        position: relative;
        height: 15px;
        button.close {
            position: absolute;
            right: 10px;
            top: -10px;
        }
    }
    .product-quickView-content {
        .pdp-main-image {
            display: flex;
            place-content: center;
            .main-image {
                width: 100%;
                max-width: 200px;
                
                @include media-breakpoint-down(md) {
                    max-width: 80px;
                }
            }
            .frozen-badge {
                width: 100%;
                max-width: 70px;

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
            .new-product-badge {
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }
        .pdp-product-type {
            display: none;
        }
        .pdp-swiper-small {
            display: block !important;
            margin-top: 20px;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }
        .swiper-pagination {
            display: block !important;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }
        .new-product-badge-carousel {
            display: block !important;
            left: 60px;
        }
        .frozen-badge-carousel {
            display: block !important;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            text-align: center;
        }
        .pdp-col-right {
            .pdp-name .name {
                font-size: 18px;
                font-weight: 700;
                line-height: 24px;
            }
            .food-type {
                font-size: 16px;
                line-height: 24px;
                color: $color-darkGreen;
                font-weight: 700;
                text-transform: uppercase;
            }
            .price {
                &.row {
                    position: absolute;
                    bottom: 0px;
                }
                .list {
                    @include media-breakpoint-down(sm) {
                        padding-left: 0px;
                    }
                }
                .sales {
                    .discounted-price-wrapper {
                        text-decoration: line-through;
                    }
                    .value {
                        &.discounted {
                            color: $color-error !important;
                            display: flex;
                            align-items: center;
                        }
                        @include media-breakpoint-down(sm) {
                            padding-left: 0px;
                        }
                    }
                }
            }
            .prices-add-to-cart-actions {
                .col-sm-12 {
                    padding-left: none;
                    
                    @include media-breakpoint-down(sm) {
                        padding-right: 0;
                    }
                }
                .btn {
                    width: 100%;
                }
        
                @media (max-width: 543.98px) {
                    background-color: transparent;
                    box-shadow: none;
                    position: relative;
        
                    .cart-and-ipay {
                        padding-bottom: 0;
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .price {
                margin-left: 15px;
            }
            .radio-checkmark {
                width: 24px;
                height: 24px;
                margin-top: -13px;
                margin-right: -10px;
            }
        }
    }

    .selectable-bonus-product-line-item {
        margin-top: 0.2em;
        margin-bottom: 0.2em;
        padding-top: 0.3em;
        padding-bottom: 0.3em;
    }

    .beenSelected {
        background-color: $grey2;
    }
    .modal-title {
        font-size: 1em;
    }

    .product-name {
        font-size: 1.875em;
    }

    .swatch-circle {
        // $white will be replaced by color image background via Javascript
        @include swatch(2.5em, $white);
    }

    a[disabled] .swatch-circle {
        cursor: not-allowed;

        &.color-value.selected::after {
            background-color: $gray-700;
        }
    }

    .availablity-container {
        text-align: right;
    }

    .availablity-container,
    .size-chart {
        margin-top: 0.938em;
    }

    .modal-content {
        border: 1px solid rgba(0, 0, 0, 0.2);
        background-color: $color-neutral10;
    }

    .modal-body {
        overflow-y: hidden;
    }

    button.close {
        font-size: 2.5em;
        font-weight: 400;
        line-height: 1.5rem;

        &:focus,
        &:active {
            outline: none;
        }
    }

    .prices .sales {
        font-size: 1.5rem;
    }

    .promotions {
        text-align: left;
        color: $red;
    }

    .bonus-summary {
        @include media-breakpoint-down(sm) {
            font-size: 0.625em;
        }

        .bonus-product-name {
            @include media-breakpoint-down(sm) {
                padding: 0;
            }
        }
    }

    .pre-cart-products {
        margin-left: 0.125em;
    }

    .color-attribute {
        border: none;
        padding: 0;
        background: none;
    }

    .non-input-label {
        display: block;
        margin-bottom: 0.5rem;
    }
}

.quick-view-dialog {
    .pdp-swiper-small {
        .carousel-image {
            height: auto !important;
            width: auto !important;
            max-width: 403px;
        }
    }

    .promotion-callout-group {
        img {
            width: 11px;
            height: 13px;
            margin-right: 10px;
            margin-bottom: 3px;
        }

        .promotion-msg {
            background-color: $color-neutral40;
            color: $color-success;
            font-size: 12px;
            line-height: 18px;
            padding: 4px 10px;
        }
    }
}
