.product-tile{
    .product-number-rating{
        display: flex;
    }
    .feefowidget-product-stars .feefowidget-star{
        font-size: 12px !important;
        line-height: 12px;
    }
    .feefo-product-stars-widget{
        background-color: $color-white;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0 6px;
        gap: 8px;
        .feefowidget-star{
            &:before{
                color: $color-gold;
            }
        }

        .summary-rating {
            .feefo-rating-stars {
                display: flex;
                align-items: center;
                line-height: unset;
                @include media-breakpoint-down(sm) {
                    display: inline-block;
                }
            }

            .reviews-count {
                color: $color-black;
            }
        }
    }
    &.product-tile{
        .image-container {
            @include media-breakpoint-down(md) {
                margin-bottom: 8px;
            }
        }
        .tile-feefo{
            @include media-breakpoint-up(lg) {
                margin-bottom: 1rem;
                margin-top: -25px;
                height: 30px;
            }

            @include media-breakpoint-down(md) {
                margin-top: 0;
                margin-bottom: 16px;
                width: 100%;
            }

            .product-number-rating{
                margin: 0 auto;
                place-content: center;
            }
            .ratings{
                z-index: 99;
                border-radius: 16px;

                &:has(.feefo-rating-stars) {
                    box-shadow: 0px -4px 12px rgba(68, 68, 68, 0.3);
                }
            }
        }
        .tile-feefo-sm{
            justify-content: center;
            .feefo-product-stars-widget{
                background: none;
                padding: 0;
            }
        }
    }
    .feefowidget-product-stars__reviews-count{
        color: $color-black;
        font-size: 12px;
        font-feature-settings: "tnum";
    }
}

.drop-element.drop-theme-feefo, .drop-element.drop-theme-feefo-no-triangle{
    display: none !important;
}

.pdp-feefo-stars {
    gap: 16px;

    .pdp-feefo-star-rating {
        font-size: 16px;
    }

    .pdp-feefo-back-stars {
        gap: 4px;
        color: $color-neutral20;
    }

    .pdp-feefo-front-stars {
        gap: 4px;
        color: $color-darkGreen70;
        top: 0;
    }

    .pdp-feefo-stars-link {
        border-radius: 0;
        color: $color-darkGreen70;
        font-family: $font-qualion;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        border-bottom: 1px solid $color-darkGreen70;

        &:hover {
            text-decoration: none;
        }
    }
}

.pdp-feefo-product-reviews-wrapper {
    background-color: $color-white;

    .pdp-feefo-product-reviews-container {
        padding: 48px 24px;
        gap: 48px;

        @include media-breakpoint-up(md) {
            padding: 48px 0;
            margin: 0 80px;
            max-width: 1400px;
        }
    }

    .pdp-feefo-product-reviews-header,
    .pdp-feefo-product-reviews-header-info {
        gap: 24px;
    }

    .pdp-feefo-product-reviews-title {
        color: $color-darkGreen70;
        font-family: $font-qualion;
        font-size: 28px;
        font-weight: 700;
        line-height: 42px;
    }

    .pdp-feefo-product-reviews-button {
        height: 56px;
        padding: 16px 24px;
        gap: 16px;
        width: 320px;
        height: 56px;
    }

    .pdp-feefo-product-reviews-gap {
        gap: 48px;
    }

    .pdp-feefo-product-reviews-review {
        border-top: 1px solid $color-neutral20;
        flex: 1 1 33%;
    }

    .pdp-feefo-product-reviews-review-date {
        color: $color-darkGreen70;
        font-family: $font-qualion;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
    }

    .pdp-feefo-product-reviews-review-text,
    .pdp-feefo-product-reviews-review-name-verified {
        color: $color-darkGreen70;
        font-family: $font-qualion;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    .pdp-feefo-product-reviews-review-name {
        color: $color-darkGreen70;
        font-family: $font-qualion;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
    }

    .pdp-feefo-product-reviews-icon {
        width: 20px;
        height: 20px;
        color: $color-white;

        &.green {
            color: $color-darkGreen70;
        }
    }

    .pdp-feefo-product-reviews-summary-rating {
        gap: 16px;
    }

    .pdp-feefo-product-reviews-summary-rating-text {
        color: $color-darkGreen70;
        font-family: $font-qualion;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
    }

    .pdp-feefo-product-reviews-summary-rating-border {
        border-right: 1px solid $color-darkGreen70;
    }

    .pdp-feefo-product-reviews-summary-verified {
        color: #292F36;
        font-family: $font-qualion;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    .pdp-feefo-product-reviews-summary-sort {
        height: 44px;
        width: 160px;
        padding: 12px 26px 12px 12px;
        border-radius: 4px;
        border: 1px solid $color-neutral20;
        color: $color-neutral80;
        font-family: $font-qualion;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        appearance: none;
    }

    .pdp-feefo-product-reviews-summary-sort-icon {
        height: 12px;
        width: 12px;
        top: 50%;
        transform: translateY(-50%);
        right: 12px;
    }
}
