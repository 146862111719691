.pd-advice-information {
    height: 500px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    @include media-breakpoint-down(sm) {
        height: auto;
    }

    img {
        position: absolute;
        bottom: 0;
        left: -56%;
        max-width: 100%;
        
        @media (min-width: 440px) and (max-width: 543px) {
            left: -30%;
            max-width: 80%;
        }
        @media (min-width: 544px) and (max-width: 749px) {
            max-width: 50%;
            left: 0;
        }
        @media (min-width: 750px) and (max-width: 1199px) {
            left: 0;
            max-width: 23%;
        }
        @include media-breakpoint-up(xl) {
            max-width: 27%;
            left: 0;
        }
    }

    &__content {
        width: 700px;
        height: 318px;
        padding: 48px 60px;
        margin-bottom: 60px;
        border-radius: 4px;

        @include media-breakpoint-down(sm) {
            width: 200px;
            height: auto;
            padding: 0;
            margin-left: 40%;
            margin-right: 15px;
            margin-top: 60px;
            margin-bottom: 30px;
        }

        .pd-advice-information__title {
            .firstLine {
                font-size: 20px;
                margin-bottom: 0;
                @include media-breakpoint-down(sm) {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            .secondLine {
                font-size: 32px;
                line-height: 40px;
                font-weight: 700;
                margin-bottom: 0;
                @include media-breakpoint-down(sm) {
                    font-size: 28px;
                    line-height: 34px;
                }
            }
        }

        .pd-advice-information__text {
            font-size: 18px;
            line-height: 26px;
            @include media-breakpoint-down(sm) {
                font-size: 16px;
                line-height: 24px;
            }
        }

        a {
            width: fit-content;

            button {
                width: 219px;
                height: 56px;
                font-size: 18px !important;
                line-height: 18px !important;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;

                button {
                    width: 100%;
                }
            }
        }
    }
}
