@import "variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/mixins/breakpoints";
@import "custom/variables/variables";
@import "custom/utils/mixins/mixins";
@import "custom/components/buttons";

.page {
    background-color: $color-neutral10;
}

.not-found-page {
    padding-top: 30px;

    .error-title {
        text-align: center;
        text-transform: uppercase;
        font-family: $font-qualion;
        font-size: $title-04--desktop;
        color: $color-darkGreen;
        font-weight: 700;

        @include media-breakpoint-down(sm) {
            font-size: $title-02--mobile;
        }
    }

    .error-message {
        font-family: $font-qualion;
        font-size: $title-02--desktop;
        font-weight: 400;
        margin: 32px 0px 40px;

        @include media-breakpoint-down(sm) {
            font-size: $text-03--mobile;
            margin: 24px 0px 10px;
        }
    }

    .picture-dog {
        svg {
            display: flex;
            margin: 0 auto;
            width: 100%;
            margin-bottom: 50px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 24px;
            }
        }
    }

    .btn-primary {
        max-width: 300px;
        margin: 0 auto;
        margin-bottom: 60px;
        padding: 26px;
        font-size: $text-03--desktop;

        @include media-breakpoint-down(sm) {
            margin-bottom: 32px;
            font-size: $text-02--mobile;
            max-width: 100%;
            padding: 10px 16px 10px 16px;
        }
    }
}
