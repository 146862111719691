@include media-breakpoint-down(sm) {
    .avoidLiveChatOverlap{
        .embeddedServiceHelpButton .helpButton{
            bottom: 12.5% !important; //keep important to avoid problems on iOS 15+
        }

        .embeddedServiceHelpButton .helpButton .uiButton{
            height: 48px;
            width: 48px;
            border-radius: 50%;
        }

        .embeddedServiceHelpButton .uiButton{
            min-width: auto;
            max-width: auto;
        }

        .embeddedServiceIcon{
            text-align: center;
            margin: 0 auto !important;
        }

        .embeddedServiceHelpButton .uiButton .helpButtonLabel{
            display: none;
        }
    }
}
