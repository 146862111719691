.pdp-info-detail-bg {
    background-color: $color-darkGreen;
}

.pdp-info-detail-wrapper {
    background-color: $color-darkGreen;
    max-width: 100%;
    padding-top: 70px;
    padding-bottom: 70px;
    @include media-breakpoint-down(sm) {
        padding-top: 20px; 
    }
    
    &.specialRange {
        padding-right: 0;
        padding-left: 0;
    }

    .pdp-info-detail {
        margin: 0;

        .pdp-product-information, .pdp-product-details {
            max-width: 512px;
            margin: 0 auto;
        }
        .pdp-product-details {
            &.specialRange {
                max-width: 100%;
                margin: 0;
                padding-right: 0;
                padding-left: 0;

                @include media-breakpoint-down(md) {
                    margin-top: 60px;
                }
                @include media-breakpoint-down(sm) {
                    margin-top: 0;
                }
            }
        }
        .pdp-product-information {
            @include media-breakpoint-down(sm) {
                order: 2;
            }
        }
        .pdp-product-details {
            @include media-breakpoint-down(sm) {
                order: 1;
            }
        }
        svg {
            max-width: fit-content;
        }

        @include media-breakpoint-down(sm) {
            margin: 0 2%;
        }

        .product-details-title, .product-information-title {
            color: #F4F9F5;
            text-align: center;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            font-family: $font-qualion;
            border-bottom: 1px solid #F4F9F5; 
            line-height: 0.1em;
            margin: 3px 0 20px;
            span {
                background: $color-darkGreen; 
                padding: 0 10px; 
            }
        }

        .product-information-text {
            b {
                font-size: 20px;
                line-height: 30px;
            }

            color: #F5F0ED;
            width: 100%;
            font-size: 20px;
            line-height: 28px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 14;

            &.expand {
                -webkit-line-clamp: unset;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
                font-size: 16px;
                line-height: 24px;
                -webkit-line-clamp: 10;
            }
        }

        .product-information-button {
            color: $color-white;
            font-size: 14px;
            line-height: 20px;
            text-decoration: underline;
            font-weight: 700;
        }
        .product-details-benefits-item {
            color: $color-white;
            font-family: $font-qualion;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
    }
}

.pdp-food-image {
    height: 270px;
    background-color: $color-darkGreen;
    background-repeat: no-repeat;
    background-position-x: center;

    @include media-breakpoint-down(sm) {
        border-radius: 0;
        background-size: 95%;
        height: 170px;
    }
}
.pdp-food-image-range { 
    .food-image {
        height: 545px;
        width: 50vh;
        margin-left: auto;
        background-color: $color-darkGreen;
        background-repeat: no-repeat;
        background-position-y: top;

        @include media-breakpoint-down(sm) {
            display: none !important;
        }
    }
}
.pdp-range-info-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
    
    .row {
        @include media-breakpoint-down(sm) {
            margin-left: 5px;
            margin-right: 5px;
        }
    }
    .product-range-info {
        max-width: 615px;
        margin: 0 auto;
        text-align: center;

        .product-range-title {
            color: $color-darkGreen;
            text-align: center;
            font-size: 26px;
            line-height: 0.1rem;
            font-weight: 700;
            font-family: $font-qualion;
            margin: 10px 0 20px;
            span {
                background: #C1CB88; 
            }
            .product-range-title-1 {
                border-bottom: 1px solid $color-darkGreen;
                line-height: 0.1rem;
                margin-bottom: 30px;
                font-weight: 800;
                @include media-breakpoint-down (sm) {
                    font-size: 20px;
                }
                span {
                    padding: 0 10px;
                }
            }
            .title-2 {
                font-size: 0;

                .product-range-title-2 {
                    font-size: 30px;
                }
                .product-range-title-3 {
                    font-size: 16px;
                }
            }
        }
        .product-range-text {
            color: #0C2821;
            font-family: $font-qualion;
            font-size: 16px;
            line-height: 28px;
            font-weight: 400;
        }
    }

    .product-range-images {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            @include media-breakpoint-down (sm) {
                max-width: 250px;
                margin-top: 60px;
            }
        }
    }

}

