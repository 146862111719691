@charset "UTF-8";

@import "variables";
@import "skin/skinVariables";
@import "bootstrap-custom-import";
@import "bootstrapOverrides";
@import "utilities/responsiveUtils";
@import "font-awesome/scss/font-awesome";
@import "flag-icon";
@import "components/common";
@import "components/header";
@import "components/footer";
@import "components/hero";
@import "components/headers";
@import "components/notification";
@import url(https://fonts.googleapis.com/css?family=Dosis&subset=latin-ext);
@import "components/pricing";
@import "components/productTiles";
@import "components/tabs";
@import "components/breadcrumb";
@import "components/spinner";
@import "components/footerSubscribe";
@import "components/live-chat";
@import "components/cookies";
@import "miniCart";
@import "termsAndPrivacy";
@import "errorPage";
@import "faqPage";
@import "components/tooltip";
@import "components/headerSearch";
@import "~slick-carousel/slick/slick.scss";

@font-face {
  font-display: swap;
  font-family: "qualion";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/qualionRegular.otf"); }

@font-face {
  font-display: swap;
  font-family: "qualion";
  font-style: italic;
  font-weight: normal;
  src: url("../fonts/qualionRegularItalic.otf"); }

@font-face {
  font-display: swap;
  font-family: "qualion";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/qualionBold.otf"); }

.page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .site-footer {
        margin-top: auto;
    }
}
