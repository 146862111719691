@import "toastMessage";
@import "../custom/variables/variables";

.attribute {
    margin-top: 0.938em;

    label {
        display: block;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 17px;
        color: $color-neutral40v2;
    }
}
.pdp-bundle-selector {
    label {
        display: block;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 17px;
        color: $color-neutral40v2;
    }
    .selected {
        border-color: $color-darkGreen;
    }
    .bundle-selector-weight {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }
}

.swatch a {
    text-decoration: none;
}

.primary-images {

    @include media-breakpoint-down(xs) {
        margin: 0;
        padding: 0;
    }
}

.prices-add-to-cart-actions {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 50;
    padding-right: 0;
    padding-left: 0;
    left: 0;

    @include media-breakpoint-down(xs) {
        background-color: rgba(255, 255, 255, 0.95);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    }

    @include media-breakpoint-up(sm) {
        position: static;
        padding: 0 0.9375em;
    }

    .price {
        text-align: center;
    }
}

.prices {
    padding-bottom: 0.5em;
    padding-top: 0.5em;
    text-align: center;
}

.cart-and-ipay {
    text-align: center;

    @include media-breakpoint-only(xs) {
        padding-bottom: 26px;

        .btn {
            width: 98%;
            margin: 1%;
            display: block;
        }
    }
}

.add-to-cart-messages,
.account-messages {
    z-index: 101;
    @include toast-message();
}

.flash-messages {
    z-index: 101;
    @include toast-message();
}

.add-to-basket-alert,
.user-unsubscribed-alert {
    z-index: 101;
    @include toast-alert();

    background-color: $color-success-light;
    color: $black;
    display: flex;
    align-items: center;
}

.remove-from-cart-messages {
    z-index: 101;
    @include toast-message();
}

.remove-from-cart-alert {
    z-index: 101;
    @include toast-alert();
}

.simple-quantity {
    margin-top: 1em;
}

.main-attributes {
    margin-top: 1em;
}

.size-chart {
    margin-top: 1.071em;
}

div.availability {
    margin-top: 1.071em;
}

.bundle-item {
    padding-bottom: 1em;
    border-bottom: 1px solid $hr-border-color;

    &:last-child {
        border-bottom: none;
    }
}

.container.product-detail {
    margin-top: 0;
    margin-bottom: 3.75em;
    padding: 0;
    max-width: 1183px;

    @include media-breakpoint-down(sm) {
        margin-bottom: 2em;
    }
}

.product-option:not(:first-child) {
    margin-top: 1.071em;
}

.pdp-shipping-cost-info {
    color: $color-black;
    font-size: 14px;
    line-height: 24px;

    b {
        color: $color-darkGreen;
    }
}

.pdp-bundle-selector-wrapper {
    border-top: 1px solid $color-neutral20;

    @include media-breakpoint-down(sm) {
        border-top: none;
    }

    .pdp-bundle-selector {
        label {
            color: $color-neutral40v2;
            font-family: $font-qualion;
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;

            .bundle-selector-label-only-size {
                color: $color-darkGreen;
                font-family: $font-qualion;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
            }
        }

        .bundle-selector {
            gap: 8px;

            .tab-bundle {
                border-radius: 4px;
                border: 1px solid $color-darkGreen;

                &.selected {
                    background-color: $color-darkGreen;

                    .bundle-selector-weight {
                        color: $color-white;
                    }
                }

                &.disabled {
                    border: 1px solid $color-neutral30;
                }
            }

            .bundle-selector-weight {
                color: $color-darkGreen;
                font-family: $font-qualion;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
            }
        }
    }
}

.pdp-bundle-selector-wrapper {
    .pdp-weight-attribut-selector {
        .bundle-selector {
            gap: 0;
        }

        .pdp-weight-attribut-selector-tab {
            padding: 12px 24px;
            background: $color-neutral5;
            cursor: pointer;

            &.selected {
                background: $color-white;

                .pdp-weight-attribut-selector-tab-title-weight {
                    color: $color-darkGreen70;
                }
            }
        }

        .pdp-weight-attribut-selector-tab-title-weight {
            color: $color-neutral80;
            font-family: $font-qualion;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
        }
    }
}