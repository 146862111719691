.product-detail {
    .price {
        color: $color-black;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        &.row {
            position: absolute;
            bottom: 0px;
        }

        .value,
        .strike-through {
            color: $color-black;
        }

        .sales {
            .value {
                font-size: 16px;
                line-height: 24px;
            }

            &:not(.black-total) {
                .value {
                    color: $color-error;
                }
            }
        }
    }
}
