.frozen-badge {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 2;
}

.frozen-badge-carousel {
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 0;
    left: 16px;
    z-index: 2;
}

.technology-badge {
    padding: 8px 12px;
    border-radius: 8px;
    color: $color-darkGreen;
    font-family: $font-qualion;
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    background-color: $color-info-blue;

    &.frozen {
        background-color: $color-info-blue;
    }

    &.dry {
        background-color: $color-secondary-pink;
    }

    &.wet {
        background-color: $color-secondary-green;
    }

    @include media-breakpoint-down(sm) {
        padding: 4px 8px;
        font-size: 12px;
        line-height: 19px;
        color: $color-neutral80;
    }
}
