.new-product-badge {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}

.subscribe-save-badge {
    width: 48px;
    height: 48px;
    position: absolute;
    top: -13px;
    left: 0;
    z-index: 2;
    left: 185px;
}

.new-product-badge-carousel {
    width: 60px;
    height: 30px;
    position: absolute;
    top: 40px;
    left: 40px;
    z-index: 2;

    @include media-breakpoint-down(md) {
        top: 32px;
        left: 32px;
    }
}

.subs-and-save-flex {
    text-transform: none;
    color: $color-darkGreen;
    margin: 10px 0 16px;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            padding: 0 0 0 17px;
            background-image: url('../../images/svg/system/checkmark.svg');
            background-repeat: no-repeat;
            background-position: 0 5px;
            background-size: 10px;
            line-height: 1.5;
        }
    }
}
