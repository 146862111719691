@import "variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";
@import "custom/variables/variables";
@import "custom/utils/mixins/mixins";

.privacy-page {
    padding: 40px 0px 60px;

    @include media-breakpoint-down(sm) {
        padding: 24px 0px 24px;
    }

    .container {

        @include media-breakpoint-down(sm) {
            padding: 0;
        }

        .content-header {
            margin-bottom: 32px;
            font-size: $title-04--desktop;
            font-family: $font-qualion;
            color: $color-darkGreen;
            font-weight: 700;
            text-transform: uppercase;

            @include media-breakpoint-down(sm) {
                text-align: center;
                font-size: $title-02--mobile;
                margin-bottom: 24px;
            }
        }

        .privacy-wrapper {
            display: block;

            @include media-breakpoint-up(sm) {
                display:  flex;
                justify-content: space-between;
                gap: 48px;

                @include media-breakpoint-down(md) {
                    gap: 20px;
                }
            }

            .privacy-menu {
                height: fit-content;
                border: 1px solid $color-neutral20;
                border-radius: $border-radius-base;
                flex: 35%;

                @include media-breakpoint-down(md) {
                    flex: 45%;
                }

                .menu-item {
                    font-family: $font-qualion;
                    color: $color-neutral80;

                    .menu-item-title {
                        font-size: $text-02--desktop;
                        font-weight: 700;
                        padding: 16px 16px;
                        cursor: pointer;
                        position: relative;
                        h2{
                            font-size: unset;
                            font-family: unset;
                            font-weight: unset;
                        }
                        @include media-breakpoint-down(md) {
                            padding-right: 30px;
                        }

                        img {
                            position: absolute;
                            right: 18px;
                            top: 25px;
                        }

                        &.open {
                            background-color: $white;
                            img {
                                transform: rotate(180deg)
                            }
                        }

                        &:hover {
                            background-color: $white;
                        }
                    }
                }

                .menu-item-links,
                .privacy-item-links {
                    font-family: $font-qualion;
                    color: $color-neutral80;
                    font-size: $text-02--desktop;
                    font-weight: 700;
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    display: none;

                    @include media-breakpoint-up(sm) {
                        display: block;
                    }

                    li {
                        padding: 13px 16px !important;
                        cursor: pointer;
                        
                        &.active {
                            background-color: $white;
                        }

                        &:hover {
                            background-color: $white;
                        }


                        a {
                            color: $color-neutral70 !important;
                            &:hover {
                                text-decoration: none !important;
                            }
                        }
                    }
                }

                .menu-title {
                    font-family: $font-qualion;
                    color: $color-neutral80;
                    font-size: $text-02--desktop;
                    font-weight: 700;
                    border-radius: $border-radius-base;
                    position: relative;
                    display: block;
                    padding: 13px 16px;
                    cursor: pointer;
                    background-color: white;

                    @include media-breakpoint-up(sm) {
                        display: none;
                    }

                    &.hide {
                        display: none;
                    }

                    img {
                        position: absolute;
                        right: 20px;
                        top: 22px;
                    }
                }
            }

            .privacy-answer-wrapper {
                flex: 65%;

                @include media-breakpoint-down(md) {
                    flex: 55%;
                }

                .privacy-block {
                    background-color: $white;
                    padding: 48px;
                    height: fit-content;
                    border-radius: $border-radius-base;
                    font-family: $font-qualion;
                    font-weight: 400;
                    color: $color-neutral80;
                    display: none;

                    &.active {
                        display: block;
                    }
    
                    @include media-breakpoint-down(sm) {
                        margin-top: 8px;
                        padding: 16px;
                    }
    
                    h2 {
                        font-family: $font-qualion;
                        font-size: $text-04--mobile;
                        color: $color-darkGreen;
                        margin-bottom: 16px;
                        font-weight: 700;
    
                        @include media-breakpoint-down(sm) {
                            padding-right: 30px;
                        }
                    }
    
                    .privacy-text-top {
                        font-size: $text-02--desktop;
                        font-family: $font-qualion;
                        margin-bottom: 6px;
                    }
    
                    .privacy-text-mid {
                        font-size: $text-02--desktop;
                        font-family: $font-qualion;
                        margin-bottom: 16px;
                    }
    
                    .privacy-text-green {
                        font-family: $font-qualion;
                        font-weight: 700;
                        font-size: $text-01--desktop;
                        color: $color-darkGreen;
                        text-transform: uppercase;
                        line-height: 24px;
                    }
                }
            }

            .privacy-text-mid-left,
            .privacy-text-mid-right {
                img {
                    @include media-breakpoint-down(md) {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}

.blog-button {
    height: 44px;
    padding: 10px 16px;
    color: $white;
    font-family: $font-qualion;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    border-radius: 4px;
    background-color: $color-darkGreen70;
    text-decoration: none !important;
    display: inline-block;

    &:hover {
        background-color: $color-lightGreen;
        color: $white;
    }

    @include media-breakpoint-up(md) {
        height: 56px;
        padding: 16px 24px;
    }
}
