.feeding-guidelines-table {
    .dropdown-asset-content {
        display: flex;
        justify-content: center;
        max-width: 487px;
        margin: 0 auto;
        font-size: 12px;
        line-height: 18px;

        .table {
            border: 1px solid $color-darkGreen70;
            border-radius: 12px;
            b {
                font-weight: 700;
            }
            .row {
                height: 32px;
                border-bottom: 1px solid $color-darkGreen70;
                .col-4 {
                    padding-top: 7px;
                    border-right: 1px solid $color-darkGreen70;
                    &:last-of-type {
                        border-right: none !important;
                    }
                    .logo {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 24px;
                        margin: 0px auto 5px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                &:last-of-type {
                    border-bottom: none !important;
                }
            }
            .row:first-child {
                height: 96px;
                .col-4 {
                    padding-top: 17px;
                    height: 100%;
                }
            }
        }
    }
}
