.bundle-selector{
    gap: 0.5rem;
    &.variation-selector {
        column-gap: 0;

        .tab {
            .tab-title {
                justify-content: center;
                margin: auto;
            }

            &.tab-left {
                border-right: none;
            }
    
            &.tab-right {
                border-left: none;
            }
        }
    }

    .tab {
        height: 67px;
        position: relative;
        display: flex;

        .radio-checkmark {
            left: auto;
            right: 1.5rem;
            top: 50%;
            transform: translateY(-50%);
        }

        &.tab-bundle {
            background-color: $color-neutral10;
            border-radius: $border-radius-base $border-radius-base;
        }

        &.selected {
            .radio-checkmark {
                &::after {
                    content: '';
                    display: block;
                    width: 12px;
                    height: 12px;
                    background: map-get($colors, "darkGreenRadio");
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .tab-line {
        z-index: 3;
    }
    .tab-title {
        position: relative;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        flex: 1;
        margin-right: 75px;
        margin-left: 1rem;
        justify-content: space-between;

        @include media-breakpoint-down(sm) {
            display: inline-block;
            vertical-align: top;
        }
    }
}
