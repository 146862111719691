.pdp-container {
    .prices-add-to-cart-actions {
        .col-sm-12 {
            padding-left: 0;
        }

        .btn {
            width: 100%;
        }

        @media (max-width: 543.98px) {
            background-color: transparent;
            box-shadow: none;
            position: relative;

            .cart-and-ipay {
                padding-bottom: 0;
            }
        }
    }
}
