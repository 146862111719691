.email-signup-message {
    z-index: 1051;
    margin-left: -10px;
}
#subscriptionModal {
    .modal-dialog {
        top: 50%;
        left: 50%;
        margin: 0;
        transform: translate(-50%, -50%);
        max-width: 562px;

        @include media-breakpoint-down(sm) {
            position: fixed;
            width: 100%;
            top: auto;
            bottom: 0;
            transform: translate(-50%, 0);
            left: 50%;
            margin: 0;
            max-width: 100%;
        }
    }
    .modal-content {
        border-radius: 30px;
        .modal-header {
            border-bottom: none;
            padding-right: 36px;
            padding-bottom: 9px;
            padding-left: 36px;
            .pull-right {
                padding-top: 25px;
                &:focus {
                    outline: none;
                }
                span {
                    &:first-of-type {
                        background-image: url("../../img/icon-ui-close.png");
                        width: 14px;
                        height: 14px;
                        background-size: contain;
                    }
                }
            }
        }
        .modal-body {
            padding-top: 0;
            padding-right: 36px;
            padding-bottom: 24px;
            padding-left: 36px;
            .title {
                padding-top: 9px;
                padding-bottom: 12px;
                font-size: 24px;
                line-height: 36px;
                font-weight: 700;
                color: $color-darkGreen;
            }
            .description {
                font-size: 18px;
                line-height: 26px;
                padding-bottom: 24px;
                color: $color-neutral80;
            }
            .email {
                input:focus::placeholder {
                    color: transparent;
                }
            }
            .custom-input {
                margin-bottom: 24px;
            }
            .singup-agreement,
            .privacy-policy-agreement {
                .policyconfirm {
                   display: flex;
                   color: $color-neutral80;

                   &:has(.is-invalid) {
                       label {
                           color: $color-error;
                       }
                   }
                }
                .agreement-check,
                .login-account-policy {
                    font-size: 16px;
                    line-height: 24px;
                    color: $color-neutral80;
                    a {
                        text-decoration: underline;
                        text-underline-offset: 5px;
                        color: $color-darkGreen;
                    }
                }

                .agreement-check,
                .login-account-policy,
                .login-policy {
                    &.is-invalid {
                        color: $color-error;
                    }
                }
            }
            .singup-agreement {
                margin-bottom: 12px;
            }
            .privacy-policy-agreement {
                margin-bottom: 18px;
            }
            .action-buttons {
                display: flex;
                justify-content: space-between;
                font-size: 18px;
                line-height: 18px;
                .subscribe-email {
                    min-width: 100%;
                }
                .col-md-6 {
                    padding: 0 0 0 10px;
                    &:first-of-type {
                        padding: 0 10px 0 0;
                    }
                }
            }
            @include media-breakpoint-down(sm) {
                padding-right: 24px;
                padding-left: 24px;
                .title {
                    font-size: 24px;
                    padding-top: 0px;
                }
                .description {
                    font-size: 17px;
                }
            }
        }
    }

    .text-underline {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
            color: map-get($colors, 'lightGreen');
        }
    }
}

.newsletter-form {
    @include media-breakpoint-up(md) {
        padding-top: 61px;
    }

    .form-group {
        margin-bottom: 24px;

        @include media-breakpoint-up(md) {
            margin-bottom: 8px;
        }
    }

    .subscription-consent {
        font-size: 12px;
        line-height: 18px;
        margin: 0 0 24px;
        color: $color-white;
        max-width: 470px;

        @include media-breakpoint-up(md) {
            margin: 0;
        }

        a {
            color: $color-white;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}
