/**
 * @module scss/checkout/freeDeliveryNotice
 * 
 * @description
 * This module contains the styles for the free delivery notice.
 */
.free-delivery-notice {
    background-color: $color-white;
    border: 1px solid $color-error-light2;
    color: $color-error;
    padding: 10px 8px 12px;
    font-size: 14px;
    line-height: 20px;
    margin: 16px 0 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.success {
        color: $color-success;
        border-color: $color-success-light2;

        &::before {
            content: '';
            display: inline-block;
            width: 21px;
            height: 20px;
            background-image: url('../../img/free-delivery-icon.svg');
            background-repeat: no-repeat;
            margin: 0 4px 0 0;
        }
    }

    .minicart-footer & {
        margin: 0 0 16px;
    }
}
