.pdp-feeding {
    background-color: $color-darkGreen;
    margin-bottom: 50px;
    .pdp-feeding-image {
        text-align: center;
        padding-top: 2rem;
        
        @include media-breakpoint-down(sm) {
            margin-right: 16px;
            margin-left: 16px;
        }
        
        img {
            width: 95%;
        }
        @include media-breakpoint-up(lg) {
            text-align: right;
            padding-right: 0;
            padding-top: 4%;
            padding-bottom: 8%;
            img {
                width: 75%;
                height: auto;
                max-width: 584px;
            }
        }
    }
    .pdp-feeding-text {
        color: $color-white;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-top: -2rem;
        @include media-breakpoint-down(sm) {
            margin-right: 16px;
            margin-left: 16px;
        }
        
        p {
            margin-bottom: 0;
        }

        li {
            margin-top: 1rem;
        }
        .pdp-feeding-text-intro {
            font-size: 20px;
            line-height: 30px;
        }
        .pdp-feeding-text-title-first {
            font-size: 24px;
            line-height: 26px;
            font-weight: normal;
            display: inline-block;
            font-family: $font-qualion;
        }
        .pdp-feeding-text-title-second {
            font-size: 32px;
            line-height: 38px;
            font-weight: 700;
            margin-bottom: 1rem;
            display: inline-block;
            font-family: $font-qualion;
        }
        .pdp-feeding-text-main {
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 7;
            display: -webkit-box;

            &.expanded {
                max-height: none;
                -webkit-line-clamp: 500;
            }
        }

        .link-gold {
            font-size: 14px;
            line-height: 20px;
            cursor: pointer;
            font-weight: 700;
            margin: 1rem 0;
            display: block;
            color: #FFE163 !important;
            text-transform: uppercase;
        }
        @include media-breakpoint-up(lg) {

            padding: 4% 10% 8% 4%;
            margin-top: 0;
            
            .pdp-feeding-text-title-first {
                font-size: 24px;
                display: inline-block;
                margin-top: 8%;
            }
            .pdp-feeding-text-title-second {
                font-size: 38px;
                line-height: 30px;
                font-weight: 700;
                display: inline-block;
            }
        }

    }
}
