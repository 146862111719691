.recommendations{
    place-content: center;

    .recommendations-heading {
        width: 100%;
        max-width: 330px;
        margin: 3rem auto;
        background-color: $color-gold;
        transform: rotate(-5deg);

        .heading-wrapper {
            padding: 15px 25px 20px 25px;
        }
    }

    .recommendations-title{
        color: $color-darkGreen;
        font-size: 32px;
        line-height: 30px;
        font-weight: 700;
        &.bottom-line{
            color: $color-darkGreen;
            font-weight: normal;
            font-size: 24px;
            @include media-breakpoint-down(sm) {
                font-size: 18px;
                line-height: 17px;
            }
        }
        @include media-breakpoint-down(sm) {
            font-size: 24px;
            line-height: 17px;
        }
    }

    .product-tile {
        @include media-breakpoint-up(md) {
            height: 520px !important;
        }

        .tile-body {
            padding-bottom: 15px;
            .info-content {
                justify-content: center;
                width: 85%;
                bottom: 95px;

                @include media-breakpoint-down(sm) {
                    bottom: 20px;
                }
            }
        }
    }

    .product-tile.product-tile .tile-feefo {
        @include media-breakpoint-down(sm) {
            width: 115%;
        }
    }
}

.pdp-recommendations {
    padding: 30px 0 30px 0;

    @include media-breakpoint-down(sm) {
        padding: 32px 0 32px 0;
    }

    .pdp-recommendations-title-wrapper {
        padding: 60px 0 42px 0;

        @include media-breakpoint-down(sm) {
            padding: 0 0 24px 0;
        }
    }

    .pdp-recommendations-title {
        color: $color-darkGreen;
        font-family: $font-qualion;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        padding: 0 10px 0 10px;

        @include media-breakpoint-down(sm) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    .pdp-recommendations-title-line {
        width: 148px;
        height: 0.7px;
        background-color: $color-darkGreen;

        @include media-breakpoint-down(sm) {
            width: 53px;
        }
    }

    .pdp-recommendations-carousel {
        max-width: 1400px;
        margin: auto;
        padding: 0 0 60px 0;

        @include media-breakpoint-down(sm) {
            max-width: 100%;
            padding: 0 0 32px 0;
        }
    }

    .pdp-recommendations-swiper-prev {
        height: auto;
        width: auto;
        z-index: 2;
        left: -24px !important;

        @include media-breakpoint-down(sm) {
            left: 0 !important;
            border-radius: 0px 2px 2px 0px;
            background-color: rgba(255, 255, 255, 0.5);

            svg {
                height: 16px;
                width: 16px;

                path {
                    fill: $color-darkGreen;
                }
            }
        }

        &::after {
            content: '';
        }
    }

    .pdp-recommendations-swiper-next {
        height: auto;
        width: auto;
        z-index: 2;
        right: -24px !important;

        @include media-breakpoint-down(sm) {
            right: 0 !important;
            border-radius: 0px 2px 2px 0px;
            background-color: rgba(255, 255, 255, 0.5);

            svg {
                height: 16px;
                width: 16px;

                path {
                    fill: $color-darkGreen;
                }
            }
        }

        &::after {
            content: '';
        }
    }

    .swiper-slide {
        .product-tile {
            padding: 0;

            .tile-feefo {
                margin: 0 0 16px 0;
            }

            .tile-body {
                @include media-breakpoint-down(sm) {
                    height: 101px;
                }
            }

            .info-content {
                width: 100%;

                @include media-breakpoint-down(sm) {
                    bottom: 0;

                    .price {
                        margin-left: 0;
                    }
                }

                .tile-price-wrapper {
                    margin: 0;
                }
            }
        }
    }
}
