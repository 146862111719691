@import "variables";
@import "custom/UIKit";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

#maincontent {
    overflow-x: hidden;
}

.faq-page {
    padding: 40px 0px 60px;

    .container {

        @include media-breakpoint-down(sm) {
            padding: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 24px 0px 24px;
    }

    .back-to-questions {
        color: $color-neutral80;
        font-family: $font-qualion;
        font-size: 16px;
        font-weight: 700;
        padding-left: 30px;
        text-transform: uppercase;
        display: none;
        position: relative;

        img {
            position: absolute;
            left: 0;
            top: 5px;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 30px;
        }
    }

    .content-header {
        margin-bottom: 32px;
        font-size: $title-04--desktop;
        font-family: $font-qualion;
        color: $color-darkGreen;
        font-weight: 700;
        text-transform: uppercase;

        @include media-breakpoint-down(sm) {
            text-align: center;
            font-size: $title-02--mobile;
            margin-bottom: 24px;
        }
    }

    .faq-wrapper {
        @include media-breakpoint-up(sm) {
            display: flex;
            justify-content: space-between;
            gap: 48px;
        }

        @include media-breakpoint-down(md) {
            gap: 30px;
        }

        .faq-menu {
            border: 1px solid $color-neutral20;
            border-radius: $border-radius-base;
            flex: 35%;

            @include media-breakpoint-down(lg) {
                flex: 45%;
            }
        }

        .menu-item {
            font-family: $font-qualion;
            color: $color-neutral80;

            .menu-item-title {
                font-size: $text-02--desktop;
                font-weight: 700;
                padding: 16px 16px;
                cursor: pointer;
                position: relative;
                h2{
                    font-size: unset;
                    font-family: unset;
                    font-weight: unset;
                }
                @include media-breakpoint-down(md) {
                    padding-right: 30px;
                }

                img {
                    position: absolute;
                    right: 18px;
                    top: 25px;
                }

                &.open {
                    background-color: $white;
                    img {
                        transform: rotate(180deg)
                    }
                }

                &:hover {
                    background-color: $white;
                }
            }

            .menu-item-links {
                font-family: $font-qualion;
                color: $color-neutral80;
                font-size: $text-02--desktop;
                font-weight: 700;
                list-style-type: none;
                padding: 0;
                margin: 0;
                background-color: $white;
                display: none;

                li {
                    padding: 8px 16px !important;
                    a {
                        color: $color-neutral80 !important;
                    }
                }
            }
        }
    }

    .faq-answer-wrapper {

        @include media-breakpoint-up(sm) {
            flex: 65%;
        }

        @include media-breakpoint-down(lg) {
            flex: 55%;
        }

        .faq-block {
            display: none;
        }

        h1 {
            font-family: $font-qualion;
            font-size: $text-04--mobile;
            line-height: 30px;
            color: $color-darkGreen;
            font-weight: 700;

            @include media-breakpoint-down(sm) {
                margin-top: 40px;
            }
        }

        .faq-item {
            .faq-item-title {
                border-bottom: 1px solid $color-neutral20;
                font-family: $font-qualion;
                color: $color-neutral80;
                font-size: $text-02--desktop;
                font-weight: 700;
                padding: 16px 16px;
                cursor: pointer;
                position: relative;
                h3{
                    font-size: unset;
                    font-family: unset;
                    font-weight: unset;
                }
                @include media-breakpoint-down(sm) {
                    padding-right: 40px;
                }

                img {
                    position: absolute;
                    right: 18px;
                    top: 25px;
                }

                &.open {
                    background-color: $white;
                    border: 1px solid transparent;

                    img {
                        transform: rotate(180deg)
                    }
                }
            }

            p {
                font-family: $font-qualion;
                color: $color-neutral80;
                font-size: $text-02--desktop;
                margin: 0;
                padding: 8px 24px 24px;
                background-color: $white;
                display: none;
            }
        }
    }
}

.faq-advice-information {
    width: 100vw;
    max-width: 100vw;
    margin-left: calc(-50vw + 50%);
    height: auto;
    background-color: $color-neutral40;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    @include media-breakpoint-up(sm) {
        height: 500px;
    }


    .advice-arc {
        width: 100%;
        position: absolute;
        top: 0;
    }

    .faq-advice-img {
        img {
            position: absolute;
            bottom: 0;
            left: 0;
            max-width: 202px;

            @include media-breakpoint-up(md) {
                max-width: 31%;
            }
        }
    }

    .advice-information-content {
        width: 100%;
        padding: 48px 60px;
        margin-bottom: 175px;
        background-color: $white;
        border-radius: 4px;

        @include media-breakpoint-up(md) {
            width: 53%;
            padding: 30px 50px;
            margin-bottom: 60px;
        }

        @include media-breakpoint-up(lg) {
            width: 45%;
            padding: 48px 60px;
        }

        .advice-information-title {
            line-height: 40px;
            font-weight: 700;
            text-transform: uppercase;
        }

        .advice-information-text {
            font-family: $font-qualion;
            line-height: 26px;
        }

        a {
            width: fit-content;

            button {
                width: 220px;
                padding: 16px 24px;
                height: 56px;
                font-size: 18px;
                line-height: 18px;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;

                button {
                    width: 100%;
                    height: 44px;
                    font-size: 16px;
                    line-height: 16px;
                }
            }
        }
    }
}
