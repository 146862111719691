@import "../custom/variables/variables";

.minicart,
.cart-page {
    .quantity {
        width: 124px;
    }

    .error-available-block {
        margin-top: 1rem;
    }    

    .quantity-form {
        position: relative;
        display: flex;
    }

    .cart-quantity-input,
    .minicart-quantity-input {
        text-align: center;
        position: relative;
        z-index: 10;
        margin-top: 10px;
        background-color: transparent;
        border: 1px solid $color-neutral20;
        border-radius: 4px;
        font-size: 20px;
        font-weight: 700;
        line-height: 20px;
        color: $color-darkGreen;

        &:focus,
        &:active {
            outline: none;
            box-shadow: none;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .button-plus,
    .button-minus {
        position: absolute;
        bottom: 0;
        z-index: 11;
        height: 44px;
        line-height: 24px;
        padding: 0;
        display: flex;
        color: $color-darkGreen;

        &:focus {
            outline: none;
            box-shadow: none;
        }

        svg {
            pointer-events: none;
        }
    }

    .button-plus {
        left: 70px;
    }

    .button-minus {
        left: -10px;
    }

    input[type="checkbox"].input-switch {
        -webkit-appearance: none;
        appearance: none;
        position: relative;
        width: 44px;
        height: 25px;
        border-radius: 34px;
        border: 1px solid $color-white;
        background-color: $color-neutral20;
        cursor: pointer;
        -webkit-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;

        &:before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            margin: 1.5px;;
            background-color: $white;
            border-radius: 100%;
            transition: all 0.3s ease-in-out;
        }

        &:checked {
            background-color: $color-darkGreen;

            &:before {
                transform: translateX(+100%);
            }
        }
    }

    .repeat-order-block {
        font-size: 14px;
        line-height: 20px;
        border-radius: 4px;

        .order-sale {
            color: $color-gold;
            font-size: 12px;
            font-weight: 700;
            margin-right: 16px;
        }
    }

    .weeks-block {
        background-color: $color-subscription-interval;
        border: 2px solid $color-neutral20;
        border-radius: 4px;
    }

    .btn-week {
        width: 100%;
        height: 78px;
        background-color: $color-subscription-interval;
        border: none;
        color: $color-neutral40v2;
        font-weight: 400;
        text-transform: none;
        line-height: 24px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding-inline-start: 6px;
        padding-inline-end: 6px;

        &:focus {
            outline: none;
        }

        &.active {
            outline: none;
            background: $white;
            color: $color-darkGreen;
            font-weight: 700;

            &::after {
                content: "";
                background-color: $color-darkGreen;
                width: 43px;
                height: 4px;
                position: absolute;
                bottom: 0px;
                border-radius: 40px;
                z-index: 4;
            }
        }

        .number {
            font-size: 20px;
        }

        .week {
            font-size: 14px;
        }
    }
}

.sas-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;

    &.show {
        display: flex;
    }

    &__wrapper {
        padding: 114px 10px;

        @include media-breakpoint-up(lg) {
            padding: 50px;
        }
    }

    &__content {
        background-color: $color-neutral10;
        border-radius: 16px;
        padding: 16px 24px 43px;
        max-width: 578px;
        max-height: 100vh;
        overflow: auto;

        @include media-breakpoint-up(md) {
            max-width: 880px;
            padding: 32px 48px 50px;
        }
    }

    &__close-button {
        display: inline-block;
        border: none;
        outline: none;
        background: url('../../img/icon-ui-close.png') no-repeat center;
        width: 14px;
        height: 14px;
        cursor: pointer;
    }

    &__header {
        text-align: center;
        font-weight: 700;
        color: $color-darkGreen70;
    }

    &__title {
        font-size: 32px;
        line-height: 40px;
        margin: 12px 0 6px;

        @include media-breakpoint-up(md) {
            font-size: 40px;
            margin: 4px 0 10px;
        }
    }

    &__subtitle {
        font-size: 18px;
        line-height: 26px;

        @include media-breakpoint-up(md) {
            font-size: 24px;
            line-height: 36px;
        }
    }

    &__benefits {
        display: flex;
        margin: 25px auto 8px;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;

        @include media-breakpoint-up(md) {
            margin: 25px auto;
        }

        @include media-breakpoint-up(lg) {
            margin: 41px auto;
        }
    }

    &__benefit {
        flex-basis: 100%;
        max-width: 146px;
        margin: 0 0 17px;

        @include media-breakpoint-up(sm, (sm:360px)) {
            flex-basis: 50%;
        }

        @include media-breakpoint-up(lg) {
            flex-basis: 25%;
            margin: 0;
        }

        img {
            width: 64px;
            height: 64px;

            @include media-breakpoint-up(md) {
                width: 96px;
                height: 96px;
            }
        }

        p {
            font-size: 14px;
            line-height: 20px;
            margin: 11px auto 0;
            color: $color-darkGreen70;
            font-weight: 700;

            @include media-breakpoint-up(md) {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    button {
        &:focus {
            outline: none;
        }
    }

    &__show-intervals,
    &__confirm {
        display: block;
        width: 100%;
        border-radius: 4px;
        text-transform: uppercase;
        background-color: $gold;
        color: $color-darkGreen70;
        font-size: 18px;
        line-height: 26px;
        padding: 15px;
        height: auto;
        border: 0;
        margin: 0 0 27px;

        @include media-breakpoint-up(lg) {
            margin: 0 0 34px;
        }

        &:hover {
            opacity: 0.75;
        }
    }

    &__decline-button {
        color: $color-darkGreen70;
        text-transform: uppercase;
        font-size: 18px;
        line-height: 26px;
        padding: 5px;
        border: none;
        background: transparent;
        width: 100%;
        outline: none;
        display: block;
    }

    &__repeat-intervals {
        display: none;
    }

    &__repeat-intervals-values {
        border: 1px solid $color-neutral20;
        border-radius: 4px;
        background-color: #FFFEF1;
        margin: 0 0 24px;

        @include media-breakpoint-up(sm) {
            display: flex;
        }
    }

    &__repeat-interval {
        border: 0;
        background: transparent;
        outline: none;
        width: 100%;
        height: 63px;
        text-transform: none;

        &:hover {
            background-color: white;
        }

        .number {
            color: $color-neutral40v2;
            font-size: 20px;
            line-height: 20px;
            font-weight: 400;
        }

        .text {
            font-size: 14px;
            line-height: 20px;
            color: $color-neutral40v2;
            font-weight: 400;
        }

        &.active {
            background-color: white;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                width: 43px;
                height: 4px;
                bottom: 0;
                border-radius: 40px;
                z-index: 4;
                background-color: $color-darkGreen70;
            }
        }
    }
}

