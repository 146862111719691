.price-discount {
    background-color: $color-error;
    border-radius: 4px;
    height: 24px;
    width: 40px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $color-white;
    padding: 2px 4px;
    text-align: center;
    display: inline-block;
    margin-left: 16px;
    &-subscribe {
        background-color: $color-gold;
        color: $color-darkGreen;
    }
}
