.pdp-ingredients-info {

    .ingredients-percentage{
        font-size: 14px;
        line-height: 20px;
    }
    .pdp-flavours {
        margin: 0 7% 60px 7%;
    }
    .pdp-flavours .bundle-selector {
        height: 44px;
        gap: 0;
        @include media-breakpoint-down(sm) {
            height: 100%;
        }
        .tab {
            height: 100%;
            min-width: fit-content !important;
            border: none !important;
            background-color: #d8d5d199;
            .tab-title {
                padding: 12px 24px;
                color: $color-neutral70;
                font-size: 14px;
                font-weight: 700;
                line-height: 20px;
                margin: 0 auto;
                justify-content: center;
                @include media-breakpoint-down(sm) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            &.selected{
                background-color: #FCF9F4;
            }

            &.selected .tab-title {
                color: $color-darkGreen !important;
            }
        }
        .tab-left {
            @include media-breakpoint-down(sm) {
                border-radius: 4px 4px 0 0;
            }
        }
        .tab-right {
            @include media-breakpoint-down(sm) {
                border-radius: 0 0 4px 4px;
            }
        }
    }

    .pdp-composition, .pdp-analytical, .pdp-additives {
        margin: 0 auto;
        max-width: 700px;
        table {
            border-collapse: collapse;
            tr {
                border-bottom: 1px solid $color-neutral20;
                display: flex;
                justify-content: space-between;
                align-items: center;
                td {
                    padding-bottom: 8px;
                    padding-top: 8px;

                    &:last-child {
                        text-align: right;
                        min-width: 160px;
                        font-weight: bold;
                    }
                }
                &:last-child {
                    border-bottom: none;
                }
            }
        }

        p {
            margin-top: 8px;
        }

        .dropdown-asset {
            border: unset !important;
            padding: 10px 0 !important;

            .dropdown-title {
                border-bottom: 1px solid $color-black;
                padding: 15px 0;
                flex-wrap: nowrap !important;
            }

            .dropdown-asset-content div {
                width: 100%;

                td {
                    margin-bottom: 0px;

                    &:last-child {
                        float: right;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.pdp-composition-image-range { 
    .composition-image {
        height: 545px;
        background-color: $color-darkGreen;
        background-repeat: no-repeat;
        background-position-y: top;
        margin-left: 10px;
    
        @include media-breakpoint-down(sm) {
            height: 400px;
            width: 300px;
            margin: 0 auto;
            background-size: auto 80%;
            background-position-x: center;
            background-position-y: center;
            margin-bottom: 40px;
        }
    }
}
