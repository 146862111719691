@import "collapsibleItem";
@import "toastMessage";
@import "../custom/variables/variables";
@import "../custom/utils/mixins/mixins";

.site-footer {
    background-color: $color-darkGreen70;
    font-family: $font-qualion;

    .container-fluid {
        @include media-breakpoint-up(md) {
            padding: 0 8%;
        }
    }

    .footer-logo {
        margin: 0 0 14px;
    }

    .logo-img {
        width: 151px;
        height: 100px;
        display: inline-block;
        margin-top: -8px;

        @include media-breakpoint-up(md) {
            margin-left: -13px;
        }
    }

    .footer-newsletter {
        margin-bottom: 64px;

        @include media-breakpoint-up(md) {
            margin-bottom: 96px;
        }

        .subscribe-headline {
            font-size: 32px;
            line-height: 42px;
            font-weight: 700;
            margin: 0 0 8px;
            color: $color-white;

            @include media-breakpoint-up(md) {
                font-size: 56px;
                line-height: 89px;
            }
        }

        .subscribe-desc {
            font-size: 14px;
            color: $color-white;
            margin: 0 0 24px;
            font-size: 18px;
            line-height: 26px;

            @include media-breakpoint-up(md) {
                font-size: 18px;
                line-height: 26px;
                margin: 0;
            }
        }
    }

    .footer-element {
        margin: 0 0 32px;

        &:last-child {
            margin-bottom: 32px;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 64px;
        }

        .title {
            font-family: $font-qualion;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            color: $color-white;

            @include media-breakpoint-up(md) {
                font-size: 18px;
                line-height: 26px;
                text-transform: uppercase;
            }
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 14px;
            line-height: 22px;

            li {
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    color: $color-white;
                    text-decoration: none;
                    line-height: 22px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .social-icons {
            gap: 18px;

            a {
                &:hover {
                    svg > * {
                        fill: $color-lightGreen;
                    }
                }
            }
        }
    }

    .middle-footer {
        background-color: $color-white;
        padding: 12px 0;

        .partner-icons {
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
            justify-content: center;
            align-items: center;
        }
    }

    .bottom-footer {
        padding: 16px 0 62px;
        color: $color-white;
        font-size: 12.8px;
        line-height: 18px;

        .bottom-links {
            margin: 0 0 10px;

            @include media-breakpoint-up(md) {
                display: flex;
                gap: 24px;
            }

            a,
            span {
                color: $color-white;
                font-size: 12px;
                line-height: 18px;
            }
        }
    }
}

.simple-footer {
    background-color: $color-darkGreen70;
    padding: 16px 0;
    color: $color-white;
    font-size: 12.8px;
    line-height: 18px;

    .bottom-links {
        margin: 0 0 10px;

        @include media-breakpoint-up(md) {
            display: flex;
            gap: 24px;
        }

        a,
        span {
            color: $color-white;
            font-size: 12px;
            line-height: 18px;
        }
    }
}

.email-signup-message {
    @include toast-message();
}

.email-signup-alert {
    @include toast-alert();
}
